/* Includes all campaign specific styling */
.main {
  @apply text-lg;

  & :global(> div[data-block="core/paragraph"]:last-of-type) {
    @apply pb-10 md:pb-15 lg:pb-20;
  }

  &:global(.no-hero) {
    @apply pt-20 sm:pt-28;

    &:global(.has-admin-bar) {
      @apply pt-36;
    }
  }

  &:global(.has-admin-bar) {
    @apply pt-12 md:pt-9;
  }

  &:global(.has-sidebar) {
    @screen xl {
      width: calc(100% - 460px);
    }
  }

  /* Background */
  &:global(.theme-bg-white) {
    @apply bg-white;

    & :global(.has-accent-color .nature-invert-color) {
      @apply text-white;
    }
  }

  &:global(.theme-bg-black) {
    @apply bg-black-100;

    & :global(.has-accent-color .nature-invert-color) {
      @apply text-black-100;
    }
  }

  &:global(.theme-bg-light-lime) {
    @apply bg-campaign-light-lime-100;

    & :global(.has-accent-color .nature-invert-color) {
      @apply text-campaign-light-lime-100;
    }
  }

  &:global(.theme-bg-cyan) {
    @apply bg-dark-cyan-100;

    & :global(.has-accent-color .nature-invert-color) {
      @apply text-dark-cyan-100;
    }
  }

  &:global(.theme-bg-yellow) {
    @apply bg-campaign-yellow-100;

    & :global(.has-accent-color .nature-invert-color) {
      @apply text-campaign-yellow-100;
    }
  }

  &:global(.theme-bg-green) {
    @apply bg-green-25;

    & :global(.has-accent-color .nature-invert-color) {
      @apply text-green-25;
    }
  }

  &:global(.theme-bg-forest-green) {
    @apply bg-campaign-forest-green-100;

    & :global(.has-accent-color .nature-invert-color) {
      @apply text-campaign-forest-green-100;
    }
  }

  &:global(.theme-bg-summer-green-25) {
    @apply bg-summer-green-25;

    & :global(.has-accent-color .nature-invert-color) {
      @apply text-summer-green-25;
    }
  }

  /* Text */
  &:global(.theme-txt-white) {
    @apply text-white;
  }

  &:global(.theme-txt-light-yellow) {
    @apply text-summer-green-20;
  }

  /* Accent */
  &:global(.theme-accent-green) {
    & :global(.gravity-form) button,
    & :global(.sidebar-btn) {
      @apply bg-green-50 text-black-100 hover:bg-green-100 hover:border-green-100/20;
    }

    & :global(.has-accent-color) {
      @apply text-green-50;

      & :global(.nature-invert-color) {
        @apply bg-green-50;
      }
    }

    & :global(.progress-has-accent) {
      @apply bg-green-50;
    }

    & :global(.radio-input-container) {
      @apply hover:border-green-100 hover:bg-green-50;

      &:global(.selected) {
        @apply border-green-100 bg-green-50;
      }
    }

    & :global(.highlight) {
      @apply bg-green-50;
    }

    & :global(.menu-featured) {
      & :global(.hover-menu) {
        @apply hover:bg-green-50;
      }

      & > li {
        &::after {
          @apply bg-green-50;
        }
      }
    }

    & :global(.toggle-has-accent-color) {
      & header > div {
        @apply bg-green-50;
      }
    }
  }

  &:global(.theme-accent-dark-green) {
    & :global(.gravity-form) button,
    & :global(.sidebar-btn) {
      @apply bg-green-100 text-black-100 hover:bg-green-100/50 hover:border-green-100/20;

      &:disabled {
        @apply opacity-50 bg-green-50;
      }
    }

    & :global(.has-accent-color) {
      @apply text-green-100;
      & :global(.nature-invert-color) {
        @apply bg-green-100;
      }
    }

    & :global(.progress-has-accent) {
      @apply bg-green-100;
    }

    & :global(.radio-input-container) {
      @apply hover:border-green-100 hover:bg-green-25;

      &:global(.selected) {
        @apply border-green-100 bg-green-25;
      }
    }

    & :global(.highlight) {
      @apply bg-green-100;
    }

    & :global(.menu-featured) {
      & :global(.hover-menu) {
        @apply hover:bg-green-25;
      }

      & > li {
        &::after {
          @apply bg-green-100;
        }
      }
    }

    & :global(.toggle-has-accent-color) {
      & header > div {
        @apply bg-green-100;
      }
    }
  }

  &:global(.theme-accent-light-yellow) {
    & :global(.gravity-form) button,
    & :global(.sidebar-btn) {
      @apply text-black-100 bg-summer-green-20 hover:bg-summer-green-20 hover:border-summer-green-20;
    }

    & :global(.has-accent-color) {
      @apply text-summer-green-20;

      & :global(.nature-invert-color) {
        @apply bg-summer-green-20;
      }
    }

    & :global(.progress-has-accent) {
      @apply bg-summer-green-20;
    }

    & :global(.radio-input-container) {
      @apply hover:border-summer-green-100 hover:bg-summer-green-20;

      &:global(.selected) {
        @apply border-summer-green-100 bg-summer-green-20;
      }
    }

    & :global(.highlight) {
      @apply bg-summer-green-20 text-black-100;
    }

    & :global(.menu-featured) {
      & :global(.hover-menu) {
        @apply hover:bg-summer-green-20;
      }

      & > li {
        &::after {
          @apply bg-summer-green-20;
        }
      }
    }

    & :global(.toggle-has-accent-color) {
      & header > div {
        @apply bg-summer-green-20;
      }
    }
  }

  &:global(.theme-accent-yellow) {
    & :global(.gravity-form) button,
    & :global(.sidebar-btn) {
      @apply text-black-100 bg-campaign-yellow-100 hover:bg-campaign-yellow-110 hover:border-campaign-yellow-110;
    }

    & :global(.has-accent-color) {
      @apply text-campaign-yellow-100;

      & :global(.nature-invert-color) {
        @apply bg-campaign-yellow-100;
      }
    }

    & :global(.progress-has-accent) {
      @apply bg-campaign-yellow-100;
    }

    & :global(.radio-input-container) {
      @apply hover:border-campaign-yellow-100 hover:bg-campaign-yellow-30;

      &:global(.selected) {
        @apply border-campaign-yellow-100 bg-campaign-yellow-30;
      }
    }

    & :global(.highlight) {
      @apply bg-campaign-yellow-100;
    }

    & :global(.menu-featured) {
      & :global(.hover-menu) {
        @apply hover:bg-campaign-yellow-50;
      }

      & > li {
        &::after {
          @apply bg-campaign-yellow-100;
        }
      }
    }

    & :global(.toggle-has-accent-color) {
      & header > div {
        @apply bg-campaign-yellow-100;
      }
    }
  }

  &:global(.theme-accent-light-lime) {
    & :global(.gravity-form) button,
    & :global(.sidebar-btn) {
      @apply text-black-100 bg-campaign-light-lime-100 hover:bg-campaign-light-lime-125 hover:border-campaign-light-lime-125;
    }

    & :global(.has-accent-color) {
      @apply text-campaign-light-lime-100;

      & :global(.nature-invert-color) {
        @apply bg-campaign-light-lime-100;
      }
    }

    & :global(.progress-has-accent) {
      @apply bg-campaign-light-lime-100;
    }

    & :global(.radio-input-container) {
      @apply hover:border-campaign-light-lime-100 hover:bg-campaign-light-lime-75;

      &:global(.selected) {
        @apply border-campaign-light-lime-100 bg-campaign-light-lime-75;
      }
    }

    & :global(.highlight) {
      @apply bg-campaign-light-lime-100;
    }

    & :global(.menu-featured) {
      & :global(.hover-menu) {
        @apply hover:bg-campaign-light-lime-100;
      }

      & > li {
        &::after {
          @apply bg-campaign-light-lime-125;
        }
      }
    }

    & :global(.toggle-has-accent-color) {
      & header > div {
        @apply bg-campaign-light-lime-125;
      }
    }
  }

  &:global(.theme-accent-light-cyan) {
    & :global(.gravity-form) button,
    & :global(.sidebar-btn) {
      @apply text-black-100 bg-dark-cyan-100 hover:bg-dark-cyan-75 hover:border-dark-cyan-75;
    }

    & :global(.has-accent-color) {
      @apply text-dark-cyan-75;

      & :global(.nature-invert-color) {
        @apply bg-dark-cyan-75;
      }
    }

    & :global(.progress-has-accent) {
      @apply bg-dark-cyan-75;
    }

    & :global(.radio-input-container) {
      @apply hover:border-dark-cyan-75 hover:bg-dark-cyan-50;

      &:global(.selected) {
        @apply border-dark-cyan-75 bg-dark-cyan-50;
      }
    }

    & :global(.highlight) {
      @apply bg-dark-cyan-75;
    }

    & :global(.menu-featured) {
      & :global(.hover-menu) {
        @apply hover:bg-dark-cyan-75;
      }

      & > li {
        &::after {
          @apply bg-dark-cyan-75;
        }
      }
    }

    & :global(.toggle-has-accent-color) {
      & header > div {
        @apply bg-dark-cyan-75;
      }
    }
  }

  /* Plate color is added in @/lib/helpers/utils themePlateColor */
  /* Group color is added in core/group styling */

  /* Hero Gradient */
  &:global(.theme-hero-gradient-light-yellow) {
    & :global(.hero-gradient) {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 80%,
        rgba(248, 250, 234, 1) 100%
      );
    }
  }

  &:global(.theme-hero-gradient-white) {
    & :global(.hero-gradient) {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 80%,
        rgba(255, 255, 255, 1) 100%
      );
    }
  }

  &:global(.theme-hero-gradient-black) {
    & :global(.hero-gradient) {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(16, 16, 16, 1) 100%
      );
    }
  }

  &:global(.theme-hero-gradient-light-lime) {
    & :global(.hero-gradient) {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 80%,
        rgba(236, 255, 97, 1) 100%
      );
    }
  }

  &:global(.theme-hero-gradient-cyan) {
    & :global(.hero-gradient) {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 150, 145, 1) 100%
      );
    }
  }

  &:global(.theme-hero-gradient-yellow) {
    & :global(.hero-gradient) {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(240, 230, 10, 1) 100%
      );
    }
  }

  &:global(.theme-hero-gradient-green) {
    & :global(.hero-gradient) {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(220, 235, 206, 1) 100%
      );
    }
  }

  &:global(.theme-hero-gradient-summer-green-25) {
    & :global(.hero-gradient) {
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0) 50%,
        rgba(236, 242, 202, 1) 100%
      );
    }
  }

  /* core/heading theme */
  & :global(div[data-block="core/heading"] .has-white-color) {
    @apply text-white;
  }

  & :global(div[data-block="core/heading"] .has-dark-color) {
    @apply text-black-100;
  }

  & :global(div[data-block="theme/hero"]) {
    & :global(.text-effect) {
      &:global(.text-bg) {
        & h1 {
          @apply leading-[3.5rem] md:leading-[5.5rem];
        }
      }
    }
  }
}
